import React from "react"
import './style.css';
import Introduction from './components/Introduction'
import UploadBooks from "./components/UploadBooks";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
function App() {
  
  return (    
    <div>
      <Introduction/>
      <div className="bodyStyle">
      <BrowserRouter>
        <Switch>
        <Route path="/">
          <UploadBooks/>
        </Route>  
        </Switch>
        </BrowserRouter>
      </div>
    </div>  
  );
}
export default App;