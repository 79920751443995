import React from 'react';
import { Typography, Link, BottomNavigation,Toolbar,BottomNavigationAction,Paper } from '@mui/material';

function Footer (){
    const [value, setValue] = React.useState(0);
    
        return (

            <div>
                <Paper sx={{ position: 'fixed', bottom: 0 }} elevation={3}>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    >
          <BottomNavigationAction label="Recents"  />
          <BottomNavigationAction label="Favorites" />
          <BottomNavigationAction label="Archive"/>
        </BottomNavigation>
      </Paper>
            </div>
            
            // <div className="bottomdiv" >
            //     {'Copyright © '}
            //     <Link color="#28b362" href="https://www.dumbcharades.in/">
            //         www.dumbcharades.in
            //     </Link>{' '}
            //     {new Date().getFullYear()}
            //     {'.'}
            // </div>
        )
}
export default Footer