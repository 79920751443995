import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { initializeApp } from 'firebase/app';
import { getStorage } from "firebase/storage";
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");


const app = initializeApp({
    projectId: 'aandolanjivi',
    apiKey: '8b800ce1113d16511fecfbcd0295b96379b72042',
    storageBucket : 'gs://aandolanjivi.appspot.com'
});
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LdG5vMkAAAAACTQuw0lXHSbrhnx7d15e28wxLoD'),
    isTokenAutoRefreshEnabled: true
  });
const firestoreFunctions = getFunctions();
export const storage = getStorage(app);
//connectFunctionsEmulator(firestoreFunctions, "localhost", 5000);
export default firestoreFunctions;