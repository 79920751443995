import React, { useEffect, useState }  from 'react';
import { storage } from "./firestoreFunctions";
import TextField from '@mui/material/TextField';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { Grid } from '@mui/material';
import { httpsCallable } from 'firebase/functions';
import firestoreFunctions from './firestoreFunctions';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


function FileUploadStorage({reloadPage, closeMe}) {
const [file, setFile] = useState("");
const [amount, setAmount] = useState("");
const [flat, setFlat] = useState("");
const [pin, setPin] = useState("");
const [percent, setPercent] = useState(0);

const [loading, setLoading] = React.useState(false);
const [success, setSuccess] = React.useState(false);

function handleChange(event) {
        setFile(event.target.files[0]);
}
 
const handleUpload = () => {
        
        if (!file) {
            alert("Please upload an image first!");
        }
        
        const storageRef = ref(storage, `/bookforbook/${file.name}`);
        
        setLoading(true);
        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file);
 
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
 
                // update progress
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                const addMessage = httpsCallable(firestoreFunctions,'addBook');
                let bookObject = {
                        'imageLink' : url,
                        'sellAmount' : amount,
                        'flat' : flat,
                        'islisted' : true,
                        'listdate' : Date.now()
                }
                addMessage(bookObject).then((result) => {
                setLoading(false);
                        if(result){
                        closeMe();
                        reloadPage();
                }});
                });
            }
        );
    };
 
    return (

        <div>
        {loading ? 
                <Box className='center' sx={{ display: 'flex',  }}>
                  <CircularProgress size={68}/>
                </Box> 
              :
        <div>
                <Grid container>
                        <Grid item xs={2}/>
                        <Grid item xs={8}>    
                                <input type="file" onChange={handleChange} accept="/image/png"/>
                        </Grid>
                        <Grid item xs={2}/>
                </Grid>
                <Grid container style={{marginTop: '10px'}}>
                        <Grid item xs={2}/>
                        <Grid item xs={8}>    
                                <TextField onChange={(event)=>{setAmount(event.target.value)}} size="small" label="Sell Amount"/>
                        </Grid>
                        <Grid item xs={2}/>
                </Grid>
                
                {/* <Grid container style={{marginTop: '10px'}}>
                        <Grid item xs={2}/>
                        <Grid item xs={8}>    
                                <TextField onChange={(event)=>{setFlat(event.target.value)}} size="small" label="Collect from Flat"/>
                        </Grid>
                        <Grid item xs={2}/>
                </Grid> */}

                <Grid container style={{marginTop: '10px'}}>
                        <Grid item xs={2}/>
                        <Grid item xs={8}>    
                                <TextField onChange={(event)=>{setPin(event.target.value)}} size="small" label="Code" helperText="Is needed to de-list book"/>
                        </Grid>
                        <Grid item xs={2}/>
                </Grid>
                

                <Grid container style={{marginTop: '10px'}}>
                        <Grid item xs={2}/>
                        <Grid item xs={8}>    
                                <button onClick={handleUpload}>List it for Sell</button>
                                <p>{percent} "% done"</p>
                        </Grid>
                        <Grid item xs={2}/>
                </Grid>
        </div>
        }
        </div>
    );
}
 
export default FileUploadStorage;