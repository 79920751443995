import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { httpsCallable } from 'firebase/functions';
import firestoreFunctions from './firestoreFunctions';
import Carousel from 'react-material-ui-carousel'
import { Typography,Table,TableContainer,Paper,Grid,FormControl,InputLabel,MenuItem,Select } from '@mui/material';
import FileUploadStorage from './FileUploadStorage';
import ReactWhatsapp from 'react-whatsapp';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Footer from './Footer';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';


class UploadBooks extends React.Component {

  state = {
    carouselItems : [
      {
          name: "Eaxm Over?",
          description: "Sell your text book to to buy next grade one."
      },
      {
          name: "Read your favourite series?",
          description: "Sell to purchase new series book."
      },
      {
        name: "Book not of your use?",
        description: "Resell or Donate for others to read."
      }
    ],
    value : null,
    open : false,
    deList: false,
    searchedBooks : [],
    listedBooks : [],
    bookTitle : '',
    bookRent : '',
    dialogValue : {
      title : '',
      rent : ''
    },
    listBookObject : {
      sellAmount: '',
      title : '',
      imageLink : ''
    },
    selfBook: {
      title: '',
      imageLink : '',
      selectedFile : 'ninja.jpg',
      sellAmount : '',    
      isSelected : false
    },
    displayOthersFlag : false,
    loading : true
  }

  constructor(props){
    super(props)
    this.handleClose = this.handleClose.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.loadListedBooks = this.loadListedBooks.bind(this);
    this.verifyCaptcha = this.verifyCaptcha.bind(this);
  }

  componentDidMount() {
    this.loadListedBooks();
  }

  verifyCaptcha(token) {
    this.state.token = token;
    const addMessage = httpsCallable(firestoreFunctions,'verifyToken');
      addMessage({token: token})
      .then((result) => {
        if(result && result.data.score && result.data.score > 0.8){
          this.loadListedBooks();
        }
      });  
  }

  loadListedBooks() {
    this.setState({loading: true});
    const addMessage = httpsCallable(firestoreFunctions,'fetchBooks');
      addMessage({token: this.state.token})
      .then((result) => {

        if(result && result.data && result.data.books){
          this.setState({loading: false});
          return this.setState({listedBooks: result.data.books});
        }
      });  
  }

  toggleOpen(openValue) {
    this.setState({open: openValue});
  }
  
   handleClose = () => {
    this.setState(prevState => ({
      dialogValue : {
          title: '',
          rent : ''
        }
      }));
      this.setState(prevState => ({
        listBookObject : {
            title: '',
            imageLink : '',
            sellAmount : ''
          }
        }));
    this.toggleOpen(false);
  };

  render (){

    const {listedBooks,loading,carouselItems} = this.state;
    let whatsappLink = "https://wa.me?text=";
        
      return (
          <React.Fragment>
          {/* <Carousel>
            {
                carouselItems.map( (item, i) => <Paper style={{textAlign: "center", backgroundColor:"#93da27"}} justifyContent="center" color="text.primary">
                <Typography variant="h5" style={{textAlign: "center",fontSize: "28px", fontWeight: "700px" ,color: "#ffffff"}} justifyContent="center">{item.name}</Typography>
                <Typography style={{textAlign: "center", color: "#464646"}}>{item.description}</Typography>
                <Button style={{textTransform: "none"}} onClick={() =>{this.setState({open : true})}}>Resell Your Book</Button>
            </Paper> )
            }
          </Carousel>   */}
          <hr/>
           <Typography variant="h5" style={{textAlign: "center", fontWeight: "900", color: "#b91e1e"}} justifyContent="center">
            Books Listed in your group
           </Typography>
           <Button variant='outlined' style={{textTransform: "none"}} onClick={() =>{this.setState({open : true})}}>Resell Your Book</Button>
          
           <GoogleReCaptchaProvider reCaptchaKey="6LdG5vMkAAAAACTQuw0lXHSbrhnx7d15e28wxLoD">
              <GoogleReCaptcha onVerify={this.verifyCaptcha} />
            </GoogleReCaptchaProvider>,

          {loading ? 
            <Box className='center' sx={{ display: 'flex',  }}>
              <CircularProgress  size={68}/>
            </Box> 
          :
           <div>
           
           <Grid container>
           <Grid item xs={12}>
            <br/>
           </Grid>
           </Grid>
            { (listedBooks && listedBooks.length > 0) ?
                  <div>
                    {listedBooks.map((book, index) => <Paper key={book.id} variant="outlined" elevation={3} sx={{p:'5px', borderRadius:'20px', margin: "5px", borderColor: 'green'}}> 
                          <Grid container style={{margin:"10px"}}> 
                          <Grid item xs={6}>
                            <Grid item xs={12}>
                            { (book.imageLink) ?
                              <img style={{maxWidth: '80vw', width:'100px',height:'100px',margin:'10px', maxHeight: '30vh'}} src={book.imageLink} />
                              :
                              <span>{book.title}</span>
                            }
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <br/>
                            <Grid item xs={12}>
                              <span style={{verticalAlign: 'bottom',margin:'10px'}}>Seller : <b>{book.flat}</b></span>
                            </Grid>
                            <Grid item xs={12}>
                            { (book.sellAmount) ?
                                <span style={{verticalAlign: 'bottom',margin:'10px'}}>Price: Rs. {book.sellAmount}/-</span>
                              :
                              <span>{book.sellAmount}</span>
                            }
                            </Grid>
                            <br/>
                            <br/>
                            <Grid item xs={12}>
                              <FormControl style={{marginBottom: '10px'}}>
                                  <Select
                                    style={{backgroundColor:"#93da27",fontSize: 'smaller'}}
                                    value="10"
                                    onChange={(event) => {
                                      if(event.target.value == "10"){
                                        
                                      }else if(event.target.value == "20"){
                                        this.setState({deList : true})
                                      }
                                      
                                    }}
                                  >
                                    <MenuItem style={{backgroundColor:"#93da27"}} value={10}><a style={{textDecoration: 'none'}} href={whatsappLink +  encodeURIComponent(book.imageLink)} target="_blank">Show Interest</a></MenuItem>
                                    <MenuItem value={20}>De-list Book</MenuItem>
                                  </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                          </Grid>
                    </Paper>
                    )} 
                  </div>
                :
                <span>No books are listed yet!!</span>
            }

              <Dialog open={this.state.open} onClose={this.handleClose}>
                <DialogTitle>Select Book Cover Image</DialogTitle>
                  <DialogContent>
                  <FileUploadStorage reloadPage={() => {this.loadListedBooks()}} closeMe={() =>{this.setState({open : false})}}/>        
                  <Button onClick={() =>{this.setState({open : false})}}>Cancel</Button>
                  </DialogContent>
              </Dialog>

              <Dialog open={this.state.deList} onClose={this.handleClose}>
                <DialogTitle>Enter Code</DialogTitle>
                  <DialogContent>
                          
                  <Button onClick={() =>{this.setState({deList : false})}}>Cancel</Button>
                  </DialogContent>
              </Dialog>
            </div>
          }
          </React.Fragment>
        )
    }
}
export default UploadBooks;