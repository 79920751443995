import React from 'react';
import { 
    Typography} from '@mui/material';

    
function Introduction(props)
{
    return (
        <div className='introStyle'>
            <Typography>
                BookForBook
            </Typography>
            <Typography style={{textAlign: "center"}} justifyContent="center" color="text.secondary">
               @ Myhna Maple, Varthur Road
            </Typography>
        </div> 
    )
}
export default Introduction